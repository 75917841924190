.ProductsPage {
  height: calc(100vh - 64px);
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .container-wide, .container {
    width: calc(100% - 30px);
    max-width: calc(1300px);
  }

  .ProductFilters {
    flex-shrink: 0;
    width: 454px;
    height: 100%;
    border-right: 1px solid #eae9ea;
    box-sizing: content-box;
  }

  .Desktop-section {
    flex-basis: 100%;
    flex-shrink: 1;
    padding: 40px 60px 0px;
    // margin-bottom: 80px;
    overflow-y: scroll;
  }
}

.menuForm {
  & > div {
    padding-left: 32px;
  }
  & > div::before {
    border: 0px !important;
  }
  & > div::after {
    border: 0px !important;
  }
}

.category-menu-item {
  padding-left: 44px !important;
  position: relative;

  .category-img-container {
    width: 44px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .category-img {
      max-width: 26px;
    }
  }
}
