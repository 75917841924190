.FabricFilters .FilterButton {
  text-transform: capitalize;
  max-width: 100px;
  margin-right: 0px;
  margin-bottom: 5px;
}

.FabricFilters.noEvents .FilterButton {
  cursor: inherit;
}
.FabricFilters.noEvents .FilterButton:hover {
  background: none;
}

/* inline */
.FabricFilters.inline {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FabricFilters.inline .FilterButton {
  padding: 2px 10px 2px 3px;
  line-height: 13px;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 0;
  border-color: #DBDBDB;
}

.FabricFilters.inline .FilterButton.ColorPicker {
  background: #F3F3F3;
  border: 0;
}

.FabricFilters.inline .FilterButton.ColorPicker .arrow {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
}

.FabricFilters.inline .FilterButton.ColorPicker.open .arrow {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.FabricFilters .ColorPallete {
  position: fixed;
  bottom: 60px;
  left: 10px;
  right: 10px;
  margin: auto;
}
