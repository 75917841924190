.ParamLabels {
  margin-left: -3px;
  margin-right: -3px;

  span {
    font-size: 14px;
    display: inline-block;
    padding: 4px 10px;
    background-color: #582adf;
    margin: 5px 3px;
    border-radius: 15px;
    line-height: 20px;
    color: #ffffff;
  }
}