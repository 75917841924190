@import '../../assets/styles/variables.scss';

.GroupTitle {
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3px;
  color: $type-primary;
  text-decoration: none;
  text-transform: uppercase;

  display: block;
  margin-top: 40px;
  margin-bottom: 24px;
  padding-left: 26px;
  cursor: pointer;

  svg {
    vertical-align: middle;
    margin-top: -3px;
  }
}
