@import '@yesplz/core-web/assets/styles/variables.scss';

.YesplzCheckbox {
  font-size: 13px;
  font-weight: bold;
  color: $dark-grey;
  text-transform: uppercase;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  user-select: none;

  > input[type="checkbox"] {
    display: none;
  }

  &-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    border: 2px solid $dark-grey;
    margin-right: 12px;

    &.is-indeterminate:after {
      content: '';
      background: $dark-grey;
      width: 6px;
      height: 2px;
    }

    &.is-checked:after {
      content: '';
      background: $dark-grey;
      width: 7px;
      height: 7px;
    }

    &.is-label-first {
      margin-left: 12px;
      margin-right: 0;
    }
  }

  &.is-disabled {
    color: $type-disabled;
  }
}
