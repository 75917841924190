.thumbnail-item {
  width: 64px;
  height: 64px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}

$col-stride: -80px;
$row-stride: -100px;

.silhouetteTN {
  width: 64px;
  height: 64px;
  display: inline-block;
  -webkit-background-size: auto;
  background-size: auto; // Show in original size
  background-repeat: no-repeat;

  &.checked {
    background-position: 0% 0%;
    opacity: 0.6;
    position: absolute;
    z-index: 2;
  }
  @for $row from 0 through 7 { // Max number of body parts
    @for $col from 0 through 7 { // Max number of choice per body part
      &.tn-#{$row}-#{$col} { 
        background-position: $col-stride*$col $row-stride*$row; 
      }
    }
  }
}
