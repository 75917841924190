.BreadCrumbs {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  background: transparent;
  padding: 24px 0px 24px;
  display: flex;
  align-items: center;
}

.BreadCrumbs > .container {
  display: flex;
  width: calc(100% - 24px);
}

.BreadCrumbs > *,
.BreadCrumbs > .container > * {
  font-size: inherit;
  line-height: 16px;
  color: inherit;
  text-decoration: none;
}

.BreadCrumbs > *:not(:last-child):after,
.BreadCrumbs > .container > *:not(:last-child):after {
  content: '>';
  margin-left: 7px;
  margin-right: 7px;
}

.BreadCrumbs .current {
  color: rgba(0, 0, 0, 0.87);
}
