
.yesplz-tree-view {
  ul {
    padding-left: 10px;

    li {
      cursor: pointer;

      ul {
        display: none;
      }

      &.active {
        > ul {
          display: block;
        }
      }
    }
  }

  > ul {
    padding-left: 0;
  }
}