.product-detail-container {
  width: 100%;
  min-width: 384px;
  height: auto;

  .vertical-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .product-image {
      width: 384px;
      height: 570px;
      margin-top: 26px;
    }

    .product-detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .product-name {
      margin-top: 32px;
    }

    .product-brand {
      margin-top: 8px;
    }

    .product-price {
      margin-top: 15px;

      .original {
        margin-right: 20px;
      }
    }

    .product-retailer {
      margin-top: 30px;
    }
  }

  .horizontal-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .product-image {
      width: 304px;
      height: 451px;
    }

    .product-detail {
      margin-left: 50px;
      display: flex;
      flex: auto;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .product-name {
        margin-top: 20px;
      }

      .product-brand {
        margin-top: 8px;
      }

      .product-prediction {
        margin-top: 8px;
        white-space: pre;
        font-size: 12px;
        color: rgba(0,0,0,0.84);
      }

      .product-price {
        margin-top: 15px;
        
        .original {
          margin-right: 20px;
        }
      }

      .product-retailer {
        margin-top: 30px;
      }
    }
  }

  .product-image {
    position: relative;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }

    .bug-report-icon {
      display: inline-block;
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
    }
  }

  .product-id {
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 4.1px;
    color: #000000;
  }

  .product-name {
    margin-bottom: 0;
    padding: 0;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b1b1d;
  }

  .product-brand {
    margin-bottom: 0;
    padding: 0;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b1b1d;
  }

  .product-price {
    margin-bottom: 0;
    padding: 0;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #582adf;

    .original {
      color: #1b1b1d;
      text-decoration: line-through;
    }
  }

  .product-retailer {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b1b1d;
  }
}
