@import '../../assets/styles/variables.scss';

.PageTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;

  h2 {
    font-size: 35px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 6px;
    text-transform: uppercase;
    color: $type-primary;
    margin-bottom: 0;
    display: inline-block !important;

    &::after {
      vertical-align: middle !important;
    }
  }

  &--last-title {
    h2 {
      font-weight: 600;
      &::after {
        display: none !important;
      }
    }
  }

  &:after {
    content: '';
    display: block;
    background: #000000;
    width: 200%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
