.prediction-container {
  width: 100%;

  .prediction-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #1b1b1d;
    text-transform: uppercase;
  }

  .prediction-content {
    margin-top: 46px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .sub-header {
    margin-top: 30px;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #1b1b1d;
  }

  .pred-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .pred-title {
      width: 96px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 3;
      letter-spacing: 1px;
      color: #1b1b1d;
    }

    .pred-slider {
      width: 180px;
    }

    .pred-value {
      width: 100px;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.1px;
      text-align: center;
      color: #000000;
    }
  }
}
