.SidebarProductsFilter {
  padding-left: 39px;
  padding-bottom: 32px;
  padding-right: 32px;

  .ThumbnailPicker {
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .SidebarItem {
    .ThumbnailPicker {
      margin-right: -32px;
    }
    .ThumbnailPickerOption {
      margin-right: 32px;
      & > h5 {
        width: 48px;
      }
      &-thumbnail {
        width: 48px;
        height: 48px;
        margin: 10px 0px;
      }
    }
  }

  // overrides
  // ======================================
  .StylesSelect {
    .ThumbnailPicker .ThumbnailPickerOption {
      margin: 0;

      // & > h5 {
      //   display: none;
      // }

      &-thumbnail {
        width: 64px;
        height: 64px;
        margin: 8px;
      }
    }

    .scrollbar-container {
      padding: 0 24px!important;
    }
  }

  .FilterGroupSlider-prevButton,
  .FilterGroupSlider-nextButton {
    display: none;
  }

  &-tabs .Tabs-header {
    padding: 0 24px;
  }

  .YesplzCheckbox {
    cursor: pointer;
    &-mask {
      margin-right: 16px;
    }
  }

  .FilterGroup label {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: normal;
  }
}

.add-preset-form {
  width: 100%;
  padding: 0px 12px 0px 40px;

  .style-name {
    width: 100%;
  }

  .events-container {
    width: 100%;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;

    .event-btn {
      width: 48%;
      border: 3px solid #1b1b1d;
      font-size: 13px;
      background: #ffffff;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.1px;
      text-align: center;
      color: #1b1b1d;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      outline: none;
      text-transform: uppercase;
      padding: 8px 12px;
    }
  }
}

.tab-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .tab-item {
    padding: 10px 5px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b1b1d;
    text-transform: capitalize;
    border-bottom: 3px solid transparent;

    &.active {
      border-bottom: 3px solid #1b1b1d;
    }
  }
}

.weights-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .group-title {
    margin-top: 30px;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #1b1b1d;
  }

  .pred-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .pred-title {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 3;
      letter-spacing: 1px;
      color: #1b1b1d;
      text-transform: capitalize;
      width: 90px;
      overflow-x: hidden;
    }

    .pred-slider {
      width: 180px;
    }

    .pred-value {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.1px;
      text-align: center;
      color: #000000;
      width: 60px;
      overflow-x: hidden;
    }
  }

  .group-action {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      width: 160px;
      height: 40px;
      background-color: white;
      border: 3px solid #1b1b1d;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.1px;
      text-align: center;
      color: #1b1b1d;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      &:hover {
        background-color: #000000;
        color: #FFFFFF;
      }
    }
  }
}
