.ProductsNotFound h1 {
  font-family: Muli;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #4a4a4a;
}
