@import 'assets/styles/variables.scss';

.FlatFloatButton-container {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 -8px 44px 0 rgba(0, 0, 0, 0.1);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  padding: 24px;

  position: absolute;
  z-index: 99;
  right: 0;
  bottom: 0;

  &.hidden {
    display: none;
  }
}

.FlatFloatButton {
  background-color: $accent-primary;
  width: 72px;
  height: 72px;
  border: none;
  border-radius: 36px;
  box-shadow: 0 2px 24px 0 rgba(140, 59, 255, 0.52);

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 20px;
  bottom: 30px;
  z-index: 99;

  overflow: hidden;
  outline: none;

  animation-name: fadeIn;
  animation-duration: 100ms !important;

  width: 100%;
  height: 56px;
  border-radius: 0;
  box-shadow: none;
  color: #ffffff;
  letter-spacing: 0.37px;
  font-size: 16px;
  position: static;

  &.position-top {
    position: fixed;
    bottom: auto;
    top: 75px;
  }

  &.hidden {
    display: none;
  }

  &.animateOut {
    animation-name: fadeOut;
  }

  & > img {
    position: relative;
    z-index: 1;

    margin-right: 24px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  &:hover::before {
    background: rgba(#FFFFFF, 8 / 100)
  }

  &:active::before {
    background: rgba(#1B1A21, 16 / 100)
  }

  &:disabled {
    animation: none;
    opacity: 0.3;
  }

  &.black {
    background-color: $dark-grey;
    box-shadow: 0 2px 24px 0 rgba(27, 27, 27, 0.52);
  }

  &.small {
    width: 50px;
    height: 50px;
    right: 25px;

    > img {
      width: 20px;
    }
  }

  &.medium {
    width: 60px;
    height: 60px;
    right: 20px;

    > img {
      width: 25px;
    }
  }
}
