.Base {
  display: block;
  margin: auto;
  height: 100%;
  background: #F6F4F5;
  overflow: hidden;
  /* make it fullscreen */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Base-header {
  background: white;
  position: relative;
  z-index: 9;
  /* padding: 21px 25px; */
}

.Base >.base-content {
  height: calc(100vh - 65px);
  transition: all 1.5s;
}
.Base >.base-content.login {
  height: 100vh;
}
.Base >.base-content.show.home {
  margin-top: calc(100vh - 65px);
}

.Base >.base-content.show.not-home {
  margin-top: 0;
}

.Base >.base-content .base-content {
  height: 100%;
}

.Base-headerContainer {
  margin: auto;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border-bottom: 1px solid #eae9ea;
}

.Base-header > .Base-headerContainer > * {
  flex-shrink: 1;
}

.Base-headerContainer button {
  outline: none;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.Base-rightNav {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 44px;
}

.Base-rightNav a {
  height: 64px;
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  border-left: 1px solid #eae9ea;
}

.Base-rightNav a:last-child {
  border-right: 1px solid #eae9ea;
}

.Base-header a {
  line-height: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.Base-header a img {
  vertical-align: top;
}

.Base-header .logo {
  font-size: 18px;
  letter-spacing: 7px;
  font-weight: 900;
  color: #000000;
  width: 190px;
  flex-shrink: 0;
  padding: 0 30px;
  line-height: 64px;
  border-right: 1px solid #eae9ea;
}

.Base-header .home-logo {
  background: #1b1b1d;
  width: 100px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Base-header .logout-button {
  border: 0;
  background-color: transparent;
  text-decoration: underline;
  margin-left: auto;
  margin-right: 30px;
  font-size: 14px;
}

.Base-header .menu-icon-sidebar {
  width: 100px !important;
  height: 64px;
  flex-shrink: 0;
  line-height: 64px;
  /* text-align: center; */
  background-color: black;
  color:white;
}

.Base-header .menu-icon-sidebar div {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 40px;
  text-align: center;
  transform: translateY(-2px)
}

.Base-header .menu-icon > img {
  width: 25px;
}

.Base-header .menu-icon + .menu-icon {
  /* margin-left: 40px; */
}

.bugReportModal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.bugReportModal .overlay {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(27, 27, 29, 0.8);
}

.bugReportModal .modalContainer {
  width: 90%;
  height: 80%;
  max-width: 882px;
  max-height: 433px;
  z-index: 11;
  border: 1px solid #4a4a4a;
  background: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.bugReportModal .modalContainer .image-container {
  width: 265px;
  height: 231px;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
}

.bugReportModal .modalContainer .image-container img {
  position: absolute;
  top: 0px;
}
.bugReportModal .modalContainer .image-container img.hoverYes {
   bottom: unset;
   top: -132px;
}

.bugReportModal .modalContainer .rightContainer {
  width: 60%;
  max-width: 421px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bugReportModal .modalContainer .rightContainer .report-field {
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 6.5px;
  color: #000000;
  border: 2px solid #1d1d1d;
  padding: 10px;
}

.bugReportModal .modalContainer .rightContainer .report-field > div::before,
.bugReportModal .modalContainer .rightContainer .report-field > div::after {
  border-bottom: 0px !important;
}

.bugReportModal .modalContainer .rightContainer .eventContainer {
  width: auto;
  max-width: 319px;
  margin-top: 22px;
}

.bugReportModal .modalContainer .rightContainer .eventContainer .confirmbtn {
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #1b1b1d;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.1px;
  text-align: center;
  background: #ffffff;
  color: #1b1b1d;
  text-transform: uppercase;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.bugReportModal .modalContainer .rightContainer .eventContainer .confirmbtn.hoverYes {
   background: #000;
   color: #ffffff;
}
