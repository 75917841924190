.MenuNavigation {
    // border-bottom: 1px solid #d8d8d8;
    box-shadow: inset 0 -2px 1px -2px grey;
    display: flex;
    flex-direction: row;
    button {
        height: 40px;
        background: transparent;
        outline: none;
        border: none;
        padding: 8px 0;
        margin-right: 51px;
        font-size: 13px;
        font-family: Muli;
        letter-spacing: 3px;
        color: #1b1b1d;

        &:last-child {
            margin-right: 8px;
        }
    }
    button.actived {
        border-bottom: 1px solid black;
        font-weight: 900;
    }
    .FilterIcon {
        width: 100%;
        height: 40px;
        text-align: right;
    }
}