// sections
$header-height: 124px;
$content-height: calc(100% -  #{$header-height});

/*
 * Colors
 */
$dark-grey: #1b1b1d;

$type-primary: $dark-grey;
$type-disabled: rgba($dark-grey, 0.5);

$accent-primary: #582ADF;

$background-primary: #FFFFFF;
$background-secondary: #F9F9F9;

$shadow-primary: 0 0 30px 0px rgba(0,0,0,0.2);

/*
 * Breakpoints
 * based on material.io (https://material.io/design/layout/responsive-layout-grid.html#breakpoints)
 */
$screen-xs-min: 0px;
$screen-xs-max: 599px;

$screen-sm-min: 600px;
$screen-sm-max: 959px;

$screen-md-min: 960px;
$screen-md-max: 1279px;

$screen-lg-min: 1280px;
$screen-lg-max: 1919px;

$screen-xl-min: 1920px;
