$purpley: #8c3bff;
$black: rgb(27, 27, 29);
$grey: rgba(27, 27, 29, 0.3);

.YesplzInput {
  padding: 12px 0;
}

.inp {
  position: relative;
  width: 100%;
}
.inp .label {
  position: absolute;
  top: 5px;
  left: 0;
  color: $grey;
  transform-origin: 0 0;
  transition: all 0.2s ease;

  font-family: Muli;
  font-size: 12px !important;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3px;
}
.inp .border {
  position: absolute;
  bottom: -4px;
  left: 0;
  height: 1px;
  width: 100%;
  background: $purpley;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: all 0.15s ease;
}

.inp input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  padding: 12px 0 0px 0;
  height: 38px;
  border-bottom: 1px solid $black;
  background: none;
  border-radius: 0;
  color: $black;
  transition: all 0.15s ease;

  font-family: Muli;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 3px;

  &:disabled {
    opacity: 0.3;
    border-bottom: 1px solid $grey;
  }
}
.inp input:hover {
  border-bottom: 1px solid $purpley;
}
.inp input:not(:placeholder-shown) + span {
  color: #1b1b1d;
  transform: translateY(-31px) scale(0.75);
}
.inp input:focus {
  border: none;
  background: none;
  outline: none;
}
.inp input:focus + span {
  color: $purpley;
  transform: translateY(-31px) scale(0.75);
}
.inp input:focus + span + .border {
  transform: scaleX(1);
}
