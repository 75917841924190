@import '@yesplz/core-web/assets/styles/variables.scss';

.AdvancedPreset {
  position: relative;

  .trashContainer {
    position: absolute;
    top: 0;
    right: 16px;
    display: inline-flex;

    & > div {
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 1.1px;
      text-align: center;
      color: #1b1b1d;
    }

    .DeletePresetSetting {
      margin-left: 8px;
      display: inline-block;
      background: transparent;
      border: 0px;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .GroupTitle {
    padding-left: 0px;
  }

  .slick-slider {
    max-width: 251px;

    .slick-list {
      overflow: visible;
    }
  }

  .MinimalPreset,
  .ProductGrid {
    max-width: 231px;
    margin-right: 20px;
  }

  .ProductGrid-thumbnail {
    height: 341px;
  }

  .event-container {
    display: flex;
    justify-content: space-between;

    .eventBtn {
      width: calc(50% - 8px);
      border: 3px solid #1b1b1d;
      background: #ffffff;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.1px;
      text-align: center;
      color: #1b1b1d;
      text-transform: uppercase;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.published {
        background: #000000;
        color: #ffffff;
      }
    }
  }
}
