.FilterPanel {
  position: relative;
  background: #FFFFFF;
  max-width: 414px;
  padding: 0;
  position: fixed;
  right: 0;
  left: 0;
  bottom: -1px;
  margin: 0 auto 15px;
  box-sizing: border-box;
  z-index: 999;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
}

.FilterPanel .VisualFilter {
  vertical-align: top;
}

.FilterPanel-close {
  padding-top: 20px;
  padding-left: 20px;
  position: absolute;
  right: 0;
  bottom: 100%;
}

.FilterPanel-close > img {
  vertical-align: top;
}

.FilterPanel #tap_button {
  animation-duration: 2s;
  animation-fill-mode: both;
  /* animation-name: flashingTabButton; */
  animation-iteration-count: infinite;
}

/* pulse effect */
.FilterPanel #tap_button_pulse_1,
.FilterPanel #tap_button_pulse_2 {
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-name: pulseTapButton;
  animation-iteration-count: infinite;
  transform-origin: 18px 18px;
}
.FilterPanel #tap_button_pulse_2 {
  -webkit-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s;
}


.FilterPanel .FabricFilters {
  font-size: 3.2vw;
  width: calc(100% - 100px);
  justify-content: flex-start;
  position: absolute;
  left: 10px;
  bottom: 15px;
}

.FilterPanel .FilterButton-icon {
  max-width: 25px;
  max-height: 25px;
  width: 5.5vw;
  height: 5.5vw;
  flex-shrink: 0;
}

.FilterPanel .LikeButton {
  position: absolute;
  top: 20px;
  left: 20px;
}

.FilterPanel .LikeButton > button {
  width: 30px;
  height: 30px;
}

.FilterPanel .FilterCategory {
  display: none;
}

@media (min-width: 415px) {
  .FilterPanel .FabricFilters {
    font-size: inherit;
  }
}

@media (min-width: 768px) {
  .FilterPanel .FilterCategory {
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 39px;
    z-index: 3;
  }
}

@keyframes flashingTabButton {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes pulseTapButton {
  0% {
    transform: scale(1);
    fill: rgba(99, 0, 238, 0.4);
  }
  50% {
    transform: scale(2.5);
    fill: rgba(99, 0, 238, 0.2);
  }
  100% {
    transform: scale(2.5);
    fill: rgba(99, 0, 238, 0);
  }
}

@-webkit-keyframes pulseTapButton {
  0% {
    transform: scale(1);
    fill: rgba(99, 0, 238, 0.4);
  }
  50% {
    transform: scale(2.5);
    fill: rgba(99, 0, 238, 0.2);
  }
  100% {
    transform: scale(2.5);
    fill: rgba(99, 0, 238, 0);
  }
}
