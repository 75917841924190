.ReviewProduct-product-container {
  width: 270px;
  height: auto;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .product-image {
    width: 254px;
    height: 362px;
    position: relative;

    .front-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-padding: top center;
      border: 1px solid #eae9ea40;
    }

    .bug-report-icon {
      display: inline-block;
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .product-detail {
    margin-top: 18px;
    width: 100%;

    .product-brand {
      width: 100%;
      text-align: left;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #1b1b1d;
      padding: 0;
      margin: 0;
    }

    .product-price {
      font-size: 16px;
      width: 100%;
      text-align: left;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.7px;
      color: #582adf;

      .original {
        color: #1b1b1d;
        margin-right: 20px;
        text-decoration: line-through;
      }
    }
  }
}
