.Product-List {
  width: 100%;

  .list-title {
    width: 100%;
    margin: 32px 0;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b1b1d;
  }

  .list-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
