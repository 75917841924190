.FilterButton {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 10px 3px 5px;
  border-radius: 2em;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -o-border-radius: 2em;
  margin: auto;
  user-select: none;
  -webkit-user-select: none;
  outline: none;
}

.FilterButton .FilterButton-icon {
  display: none;
}

.FilterButton.active .FilterButton-icon {
  display: flex;
  background-color: black;
}

.FilterButton:hover {
  background: rgba(251, 159, 132, .6);
}

.FilterButton h5 {
  font-size: 0.813em; /* 13px */
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  margin: auto 0;
  display: inline-block;
  vertical-align: middle;
}

.FilterButton-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 7px;
  vertical-align: middle;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  box-sizing: border-box;
}

.FilterButton-icon > img {
  max-width: 100%;
}
