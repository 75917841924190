.ProductsPage {
  height: calc(100vh - 64px);
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .container-wide, .container {
    width: calc(100% - 30px);
    max-width: calc(1300px);
  }

  .ProductFilters {
    flex-shrink: 0;
    width: 289px;
    height: 100%;
    border-right: 1px solid #eae9ea;
    box-sizing: content-box;
  }

  .Desktop-section {
    flex-basis: 100%;
    flex-shrink: 1;
    padding: 40px 60px 0px;
    // margin-bottom: 80px;
    overflow-y: scroll;
  }

  .YesplzButton {
    width: 421px !important;
    height: 40px;
    padding: 0;
    font-family: Muli;
    font-size: 16px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.1px;
  }

  .GoBack-link {
    text-align: center;
    margin: 25px;
    a {
      text-decoration: underline;
      font-family: Muli;
      font-size: 14px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: black;
    }
  }

  &-HeaderBar {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 24px;

    .SectionTitle {
      margin: 0;
    }
  }

  &-ProductList {
    // height: 100%;
    .ProductsPage-products {
      // overflow-y: scroll !important;
    }
    .ProductGrid {
      width: calc(33.33333% - 10px);

      .ProductGrid-thumbnail {
        height: 468px;
        background-size: cover;

        img {
          object-fit: cover;
        }

        @media screen and (max-width: 1119px){
          height: 300px;
        }
        @media screen and (max-width: 989px){
          height: 214px;
        }
      }
    }
    &.contained {
      .ProductGrid .ProductGrid-thumbnail img {
        object-fit: contain;
      }
    }
    &.ColTypeDouble {
      .ProductGrid {
        width: calc(25% - 10px);
        .ProductGrid-thumbnail {
          height: 300px;

          img {
            object-fit: cover;
          }

          @media screen and (min-width: 1120px){
            height: 300px;
          }
          @media screen and (max-width: 1119px){
            height: 214px;
          }
          @media screen and (max-width: 989px){
            height: 180px;
          }
        }
      }

      &.contained {
        .ProductGrid .ProductGrid-thumbnail img {
          object-fit: contain;
        }
      }
    }
  }

  .SectionTitle {
    .container-wide {
      margin: 0;
    }

    h3 {
      font-family: Muli;
      font-size: 28px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b1b1d;
      margin-bottom: 0;
    }

    &.small {
      h3 {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}

.menuForm {
  & > div {
    padding-left: 32px;
  }
  & > div::before {
    border: 0px !important;
  }
  & > div::after {
    border: 0px !important;
  }
}

.category-menu-item {
  padding-left: 44px !important;
  position: relative;

  .category-img-container {
    width: 44px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .category-img {
      max-width: 26px;
    }
  }
}
