@import 'assets/styles/variables.scss';

.ListView {
  &-option {
    width: 24px;
    height: 28px;
    display: inline-block;
    background: $dark-grey;
    opacity: 0.2;
    position: relative;
    vertical-align: top;
    cursor: pointer;

    & + & {
      margin-left: 22px;
    }

    &.is-active {
      opacity: 1;
    }

    @media (min-width: $screen-md-min) {
      height: 24px;
  
      & + & {
        margin-left: 16px;
      }
    }
  }

  &-twoCols {
    &::after,
    &::before {
      content: '';
      display: inline-block;
      background: white;
      width: 24px;
      height: 3px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0px;
      margin: auto;
    }

    &::after {
      width: 3px;
      height: 28px;
    }
  }
}
