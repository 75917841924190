$transition: all 0.2s linear;

.MobilePicker {
  display: flex;
  align-items: flex-end;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999;

  &-backDrop {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    transition: $transition;

    .entered > & {
      opacity: 1;
    }
  }

  &-content {
    background: white;
    width: 100%;
    height: 260px;
    position: relative;
    bottom: -260px;
    z-index: 2;
    transition: $transition;

    .entered > & {
      bottom: 0;
    }
  }

  &-contentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 44px;
    box-shadow: 0 -0.5px 0 0 rgba(0, 0, 0, 0.3);
    background-color: #f9f9f9;
    padding: 5px 14px;
  }

  &-button {
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    color: #007aff;
    background: none;
    border: none;
  }

  &__button-prev,
  &__button-next {
    padding: 0;
    margin-right: 14px;
    background: none;
    border: none;
  }

  &__button-prev img,
  &__button-next img {
    width: 20px;
    height: 12px;
  }

  &__button-prev img {
    transform: rotate(180deg);
  }

  .picker-item {
    text-transform: capitalize;
  }
}
