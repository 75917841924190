.simple-presets-list {
  ul {
    margin: 0;
    padding-left: 24px;
    padding-bottom: 20px;
    list-style-type: none;

    li {
      font-size: 15px;
      line-height: 1.5;
      margin-top: 10px;
      font-weight: 400;
      color: #8b8c8f;
      cursor: default;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        font-weight: 600 !important;
      }

      &[data-preset-id], &[data-occasion] {
        cursor: pointer;
        font-weight: normal;
        color: #131314;

        &.is-selected {
          font-weight: 700 !important;

          &:hover {
            font-weight: 700 !important;
          }
        }
      }
    }
  }

  &.is-titled {
    ul {
      li {
        &[data-category-id] {
          margin-left: 24px;
        }
      }
    }
  }
}
