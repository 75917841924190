.FilterNavigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  visibility: hidden; // avoid this box interfere VF touch area

  &-prevButton,
  &-nextButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    border: none;
    border-radius: 100%;
    bottom: auto;
    cursor: pointer;
    user-select: none;
    visibility: visible;

    img {
      width: 8px;
    }
    z-index : 1
  }
}
