@import './variables';
@import './mixins';

@import '~animate.css/animate.css';
@import "~@glidejs/glide/src/assets/sass/glide.core";
@import "~@glidejs/glide/src/assets/sass/glide.theme";
@import "~nouislider/distribute/nouislider.min.css";

#visualfilter-svg {
  display: block;
  @include media-breakpoint-up(md) {
    max-width: 450px;
    min-height: 215px;
    margin: 0 auto 10px;
  }
}

@import './floatButton';
@import './styleFilter';
@import './colorFilter';
@import './thumbnailPicker';
@import './productsList';
@import './visualFilter';
@import './responsiveFilter';
@import './pagination';
@import './treeViewMenu';
@import './editorsPicks';
@import './paramLabels';
@import './brandFilter';
@import './priceRangeFilter';
@import './collapsible';
@import './simpleCategoriesList';
@import './simplePresetsList';
