.root {
  margin-bottom: 2rem;

  > h3 {}

  > h4 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
    text-align: left;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;

    &.next-matches {
      margin-top: 3rem;
    }
  }

  > p {
    margin: 0 1.125rem 2rem;
    text-align: left;
    opacity: 0.6;
  }

  .products {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 0.5625rem;
  }
}
