.Comming-Soon {
  width: 100%;
  height: calc(100vh - 124px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Comming-Soon p {
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: 3px;
  text-align: center;
  color: rgba(26, 26, 26, 0.5);
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .Comming-Soon {
    height: 100%;
  }

  .Comming-Soon img {
    width: 404px;
    margin-bottom: 80px;
  }
}
