.ProductList-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  min-height: 200px;
  /* margin-left: -5px;
  margin-right: -5px; */
}

.ProductList-subtitle {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 5px;
  margin-right: 5px;
  flex-basis: 100%;
  order: 1;
}
