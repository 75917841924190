@import 'assets/styles/variables.scss';

.AdvancedFilterTabs {
  &-header {
    display: inline-flex;
    justify-content: flex-start;
    padding: 0 10px;
    margin: 0;

    & > li {
      color: $type-primary;
      font-size: 11px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2px;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;
      padding: 10px 0;
      margin: 0;
      width: 27vw;
      border-bottom: 2px solid transparent;

      &.is-active {
        font-weight: 900;
        border-bottom-color: $type-primary;
      }
      img {
        position: absolute;
        display: inline-block;
        padding: 0 0 0 3px;
        top: 4px;
      }
    }
  }

  &-content {
    background: white;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 138px;
    padding: 20px 0;

    > .scrollbar-container {
      height: auto;
    }
  }

  &-item {
    padding: 0 20px;
  }

  // hide scrollbar
  .ps__rail-x {
    display: none!important;
  }
}
