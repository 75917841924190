.VisualFilterBagde {
  background: #F7F7F7;
  width: 64px;
  height: 64px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 100%;
  outline: none;
  overflow: hidden;

  & > svg {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
}
