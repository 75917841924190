.Product-Page {
  padding: 60px 32px 32px 32px;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .main-content-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    .detail-container {
      width: 45%;
    }
    .pred-container {
      width: 45%;
      padding-top: 60px;
    }
  }

  .product-list-container {
    align-self: center;
    margin-top: 80px;
    margin-left: 60px;
  }
}
