.SecondaryFilters {
  font-size: 3.2vw;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  position: absolute;
  left: 20px;
  bottom: 15px;
}

.SecondaryFilters > .YesplzButton {
  font-size: 10px;
  color: #000000;
  background: transparent;
  width: 99px;
  height: 34px;
  padding: 7px 5px;
  border: 1px solid #9B9B9B;
  outline: none;
  margin: 0 5px;
}

.SecondaryFilters > .YesplzButton:hover,
.SecondaryFilters > .YesplzButton.focus {
  background: rgba(99, 0, 238, 0.4);
}

.SecondaryFilters > .YesplzButton.active {
  background: #6200EE;
  color: white;
  border-color: transparent;
}

/* color filters */
.SecondaryFilters .ColorPallete {
  position: fixed;
  bottom: 55px;
  left: 10px;
  right: 10px;
  margin: auto;
}

/* design filters */
.SecondaryFilters .DesignFilters {
  position: fixed;
  bottom: 55px;
  left: 10px;
  right: 65px;
  margin: auto;
}
