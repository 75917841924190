.Favorites {
  height: calc(100% - 165px);
  padding-bottom: 40px;
}

.Favorites .Favorites-tab-nav {
  display: flex;
  justify-content: center;
  flex-basis: 100%;
}

.Favorites .Tabs {
  font-size: 14px;
  max-width: 400px;
}

.Favorites .Tabs > a {
  font-weight: normal;
}

.Favorites .FlatBanner {
  flex-basis: 100%;
  height: 182px;
  margin-bottom: 10px;
  margin-top: -10px;
  box-sizing: border-box;
}

/* products */
.Favorites-products {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 10px;
}

.Favorites-products .Product {
  order: 0;
  margin: -8px -5px 10px;
}

.Favorites-products .ProductGridCompact {
  width: calc(33.3333% - 10px);
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  order: 1;
  min-height: 221px;
}

/* presets */
.Favorites .Presets .Preset {
  width: calc(33.3333% - 16px);
  margin: 0px 8px 10px;
  grid-template-columns: calc(100% - 120px) 100px;
  padding: 20px;
}

.Favorites .PresetsInnerHead {
  margin-top: -45px;
  pointer-events: none;
}

.Favorites .PresetsInnerHead > * {
  pointer-events: all;
}

.Favorites .PresetsInnerWrapper .Preset-svg > svg {
  max-width: 100%;
  height: 100%;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.Favorites .PresetsInnerWrapper .FilterButton {
  max-width: 100px;
  padding: 2px 5px 2px 3px;
}

/* filter buttons */
.Favorites .FilterButton {
  max-width: 118px;
  cursor: pointer;
  padding: 3px 8px 3px 3px;
  margin-bottom: 9px;
}
.Favorites .FilterButton  h5 {
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
}
.Presets .FilterButton:not(.active)  h5 {
  color: #707070;
}
.Favorites .FilterButton .FilterButton-icon {
  width: 2.986vw;
  height: 2.986vw;
  max-width: 33px;
  max-height: 33px;
  flex-shrink: 0;
  margin-right: 8px;
}

/* presets split view */
.Favorites .PresetsInnerWrapper.splitView {
  height: calc(100% - 77px);
  grid-template-columns: calc(35% - 10px) calc(65% - 10px);
}

.Favorites .PresetsInnerWrapper.splitView .Preset {
  width: 100%;
}

.Favorites .PresetsInnerWrapper.splitView .PresetsInnerWrapper-productList .ProductGrid {
  width: calc(25% - 10px);
}

/* from ScrollFetcher */
.Favorites-products .DotLoader {
  width: 100%;
  flex-basis: 100%;
  order: 2;
}

.FavoriteContent {
  display: flex;
  height: 100%;
  .left-menu {
    flex: 0 0 290px;
    padding-left: 40px;
    border-right: 1px solid #eae9ea;
    height: calc(100vh - 64px);
    .MenuNavigation {
      margin-bottom: 20px;
    }
    .content-menu {
      height: calc(100% - 60px);
      overflow-y: auto;
    }
  }
  .Favorites {
    flex: 0 0 calc(100% - 290px);
    padding-top: 38px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .AdvancedPreset {
      .MinimalPreset-title {
        display: none;
      }
    }

    .Favorites-products {
      .container {
        max-width: 100%;
        width: 100%;
      }
    }
    .title {
      display: flex;
      margin-bottom: 35px;
      padding: 0px 64px;
      >h3 {
        flex: 1;
      }
      .GridView {
        flex: 0 0 auto;
      }
    }

    .ProductsPage-ProductList {
      padding: 0px 44px;
      overflow-y: auto;

      .ProductGridCompact {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;

        .LikeButton {
          left: unset;
          right: 20px;
        }

        .ProductGridCompact-detail {
          height: auto;
        }

        .ProductGridCompact-thumbnail {
          height: 360px;
          padding: 0 5px;
          background-size: cover;

          img {
            width: 100%;
            height: 100%;
            object-position: center center;
            object-fit: contain;
          }

          @media screen and (max-width: 1119px){
            height: 300px;
          }
          @media screen and (max-width: 989px){
            height: 214px;
          }
        }
      }
    }
    .content-preset {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 64px;

      .AdvancedPreset {
        .MinimalPreset {
          margin-left: 0;
          width: auto;

          &-svg {
            height: 300px !important;
            padding-top: 18px;
            background-color: rgba(222, 225, 228, 0.21);

            @media screen and (min-width: 1380px){
              height: 300px !important;
            }
            @media screen and (max-width: 1379px){
              height: 214px !important;
              width: 229px;
            }
            @media screen and (max-width: 989px){
              height: 180px !important;
            }

            > svg {
              height: 300px !important;

              @media screen and (min-width: 1380px){
                height: 300px !important;
              }
              @media screen and (max-width: 1379px){
                height: 214px !important;
                width: 229px;
              }
              @media screen and (max-width: 989px){
                height: 180px !important;
              }
            }
          }
        }
        .ProductGrid-thumbnail {
          height: 300px;
          padding: 0 5px;

          img {
            object-fit: cover;
          }

          @media screen and (min-width: 1380px){
            height: 300px;
          }
          @media screen and (max-width: 1379px){
            height: 214px;
          }
          @media screen and (max-width: 989px){
            height: 180px;
          }
        }
        .slick-slider {
          max-width: unset;
          width: 100%;

          .slick-list {
            width: 100%;

            .slick-track {
              width: 100% !important;
              display: flex;
              .slick-slide {
                width: auto !important;
                float: none;
              }
            }
          }
        }
      }
    }
    #MainScroll {
      width: 100%;
      overflow-y: unset !important;
    }
    .ColTypeDouble {
      .Favorites-products {
        .ProductGridCompact {
          width: calc(25% - 10px);
          display: flex;
          flex-wrap: nowrap;
          flex-direction: column;

          .LikeButton {
            left: unset;
            right: 20px;
          }

          .ProductGridCompact-detail {
            height: auto;
          }

          .ProductGridCompact-thumbnail {
            height: 300px;

            img {
              width: 100%;
              height: 100%;
              object-position: center center;
              object-fit: contain;
            }

            @media screen and (min-width: 1120px){
              height: 300px;
            }
            @media screen and (max-width: 1119px){
              height: 214px;
            }
            @media screen and (max-width: 989px){
              height: 180px;
            }
          }
        }
      }
    }
  }
}

.confirmPublishModal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(27, 27, 29, 0.8);
  }

  .modalContainer {
    width: 90%;
    height: 80%;
    max-width: 882px;
    max-height: 433px;
    z-index: 11;
    border: 1px solid #4a4a4a;
    background: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .image-container {
      width: 316px;
      height: 205px;
      -webkit-background-size: cover;
      background-size: cover;
      position: relative;
      display: flex;
      justify-content: center;

      img {
        position: absolute;
        bottom: 46px;

        &.hoverYes {
          bottom: unset;
          top: -204px;
        }
      }
    }

    .rightContainer {
      width: 60%;
      max-width: 319px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        width: 100%;
        font-size: 30px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 6.5px;
        color: #000000;
      }

      .eventContainer {
        width: 100%;
        max-width: 319px;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        .confirmbtn {
          width: calc(50% - 8px);
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 3px solid #1b1b1d;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1.1px;
          text-align: center;
          background: #ffffff;
          color: #1b1b1d;
          text-transform: uppercase;
          outline: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;

          &.hoverYes {
            background: #000;
            color: #ffffff;
          }
        }
      }
    }
  }
}
