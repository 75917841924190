@mixin fullAbsolute ($value: 0) {
  position: absolute;
  right: $value;
  bottom: $value;
  left: $value;
  top: $value;
}

.ColorPallete {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 20px;

  &-clear {
    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    width: 40px;
    height: 40px;
    margin: 6px 3px;

    border: 1.25px solid rgba($dark-grey, 0.2);
    border-radius: 100%;
    outline: none;
    cursor: pointer;
  }
}

.ColorButton {
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 6px;
  position: relative;
  outline: none;
  vertical-align: top;
  overflow: hidden;

  border: 1.25px solid rgba($dark-grey, 0.1);
  border-radius: 100%;
  box-sizing: border-box;

  transition: padding 0.1s ease-in;
  cursor: pointer;

  &-color {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    box-sizing: border-box;
  }

  &.white > &-color {
    border: 1.25px solid rgba($dark-grey, 0.1);
  }

  &-mask {
    border-radius: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    @include fullAbsolute;

    &::before {
      content: '';
      border-radius: 100%;
      @include fullAbsolute;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("~@yesplz/core-models/assets/svg/check.svg");
    }

    .active > & {
      display: block;
    }
  }

  &.white > &-mask::before {
    background-image: url("~@yesplz/core-models/assets/svg/check-dark.svg");
  }
}
