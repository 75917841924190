@import '@yesplz/core-web/assets/styles/variables.scss';

.ColorPallete {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  border-radius: 20px;

  .ColorButton {
    margin: 6px 3px;

    &.white {
      border-radius: 100%;
    }
  }

  &-clear {
    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    width: 40px;
    height: 40px;
    margin: 6px 3px;

    border: 1.25px solid rgba($dark-grey, 0.1);
    border-radius: 100%;
    outline: none;
    cursor: pointer;
  }
}
