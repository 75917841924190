.Empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    p {
      height: 40px;
      opacity: 0.5;
      font-family: Muli;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.54;
      letter-spacing: 3px;
      text-align: center;
      color: var(--dark-grey);
    }
    img {
      max-width: 418px;
    }
  }